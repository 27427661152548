<template>
  <div class="flex-just">
    <div class="flex items-center">
      <div class="message-deleted" v-if="message.deleted_message">
        <q-icon size="xs" name="block" />
        <b class="q-ml-xs">Mensagem Apagada!</b>
      </div>
      <!-- :class="[isAEmoji(message.message) ? 'emoji-text' : '']" -->
      <span v-else-if="message.attachment_type == ''">
        <div v-html="formattedMessage"></div>
      </span>
      <div v-else-if="message.attachment_type == 'image'">
        <figure
          class="attachment"
          @click="viewImage(urlAwsBucket + message.attachment_path)"
        >
          <q-zoom background-color="black">
            <img
              class="my-image-portrait"
              :src="urlAwsBucket + message.attachment_path"
            />
          </q-zoom>
        </figure>
      </div>
      <div v-else-if="message.attachment_type == 'video'" class="attachment">
        <vue3-video-player :src="urlAwsBucket + message.attachment_path" />
      </div>
      <div v-else-if="message.attachment_type == 'application'">
        <a
          :href="urlAwsBucket + message.attachment_path"
          download
          target="_blank"
          class="q-pr-md"
        >
          {{ message.attachment_path }}
          <img src="../../assets/icons/download.svg" alt="download" />
        </a>
      </div>
    </div>
    <div
      class="text-grey-8 q-gutter-xs more-option"
      v-show="messageForUser && !message.deleted_message"
    >
      <q-btn size="12px" flat dense round color="default-pink" icon="more_vert">
        <q-menu>
          <q-list style="min-width: 100px">
            <q-item clickable v-close-popup @click="$emit('disabled', message)">
              <q-item-section avatar>
                <q-icon
                  size="sm"
                  color="default-pink"
                  class="material-icons-outlined"
                  name="delete"
                />
              </q-item-section>
              <q-item-section> Remover </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </div>
  </div>
  <q-dialog v-model="showImage">
    <q-card class="dialog-item q-px-sm q-pb-sm">
      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn flat round icon="close" size="md" clickable v-close-popup />
      </q-card-actions>
      <q-card-section class="full-height image column items-center">
        <img class="my-image-portrait-full" :src="imageFullView" />
        <div class="btn-fullscreen self-start">
          <q-btn
            round
            width="sm"
            :href="urlAwsBucket + message.attachment_path"
            class=""
            target="_blank"
            color="default-pink"
            icon="fullscreen"
          />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, computed } from "vue";
import LoadingIcon from "@/components/LoadingIcon";

export default {
  name: "MessageComponent",
  components: {
    LoadingIcon,
  },
  props: {
    message: Object,
    urlAwsBucket: String,
    messageForUser: {
      default: false,
      type: Boolean,
    },
  },
  emits: ["disabled"],
  setup(props) {
    const showImage = ref(false);
    const imageFullView = ref("");

    function viewImage(imageLink) {
      imageFullView.value = imageLink;
      showImage.value = true;
    }

    function isAEmoji(message) {
      return /\p{Extended_Pictographic}/u.test(message);
    }

    const formattedMessage = computed(() =>
      props.message.message.replace(/\n/g, "<br>")
    );

    return {
      showImage,
      imageFullView,
      viewImage,
      isAEmoji,
      formattedMessage,
    };
  },
};
</script>

<style lang="scss" scoped>
.flex-just {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.emoji-text {
  font-size: 24px;
}
.scroll {
  height: 70vh;
  max-height: 95vh;
  overflow: auto;
}
.chat-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  overflow: auto;
  padding: 0 2%;

  main {
    overflow: auto;

    hr {
      width: 100%;
      height: 1px;
      min-height: 1px;
      background: #e5e5e5;
      border: none;
      margin-bottom: 0.5rem;
    }
  }
  @media (min-width: 800px) {
    flex-direction: column;
  }
}
.message-container {
  max-height: 80vh;
  height: 75vh;
}

.attachment {
  height: auto;
  max-width: 300px;
}
.my-image-portrait {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  cursor: pointer;
}

.my-image-portrait-full {
  height: 90%;
  display: flex;
  align-items: center;
  width: 100%;
  object-fit: contain;
  aspect-ratio: auto 16 / 9;
}

.dialog-item {
  height: 100vh;
  max-width: 100%;
  overflow: hidden !important;
}
.message-deleted {
  // position: absolute;
  // top: 14px;
}

.btn-fullscreen {
  position: relative;
  bottom: 20px;
}
</style>
