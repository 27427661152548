<template>
  <div class="chat-content q-px-md q-pt-md">
    <Mural
      class="q-mb-sm"
      :notificationQuantity="chats.length"
      route="home"
      :query="{}"
      :breadcrumbs="breadcrumbs"
      titleButton="Novo Post"
    >
      <div class="container-options">
        <DefaultBtn
          titleButton="Criar Grupo"
          v-show="validateActionsPage('chat-add-groups')"
          @click="
            group = null;
            groupForEdit = null;
            dialogCreateGroup = true;
          "
          icon="groups"
        />
        <div class="container-status" v-if="isUserAdmin">
          <q-select
            v-model="onlineStatus"
            :options="optionsStatus"
            @update:model-value="changeOnlineStatus"
            map-options
            emit-value
            dense
            outlined
            rounded
            color="default-pink"
          >
            <template v-slot:selected-item="scope">
              <div class="container-label">
                <div class="status online" v-if="scope.opt.value"></div>
                <div class="status offline" v-else></div>
                <span class="label">{{ scope.opt.label }}</span>
              </div>
            </template>
          </q-select>
        </div>
      </div>
      <q-dialog
        v-model="dialogCreateGroup"
        :maximized="true"
        transition-show="slide-up"
        transition-hide="slide-down"
      >
        <CreateGroupChat
          ref="refCreateGroup"
          :groupForEdit="groupForEdit"
          :listUsers="listUsers"
          @create="(group) => saveGroup(group)"
          @remove="() => callRemoveGroup()"
        />
      </q-dialog>
    </Mural>
    <main class="row justify-start items-around">
      <div class="col-3 chat-content">
        <ChatUsers
          :chats="chats"
          :listUsers="listUsers"
          :options="listUsers"
          @onClickChat="(chat) => selectedChat(chat.chat, chat.index)"
          @selectUser="(user) => toggleUser(user)"
        />
      </div>
      <div class="col-9" v-if="selectedChatToUse">
        <ChatComponent
          ref="refChatComponent"
          :messages="messages"
          :userData="userData"
          :urlAwsBucket="urlAwsBucket"
          :quantityMessage="quantityMessage"
          :chat="chatInAttendance"
          @showGroup="(chat) => showGroup(chat)"
        >
          <template v-slot:loadFile>
            <div v-if="loadFile" class="collumn justify-center q-my-md">
              <div style="height: auto; width: 50px; margin: auto">
                <LoadingIcon />
              </div>
              <label class="flex justify-center"
                >Carregando arquivo...{{ loadingPostValue }}%</label
              >
            </div>
          </template>
        </ChatComponent>

        <div class="row col-12 flex input-chat">
          <div class="emoji-picker">
            <EmojiPicker v-if="showEmojiPicker" @select="addEmojiInInput" />
          </div>
          <div class="q-ml-md col-11">
            <q-input
              outlined
              ref="refInputMessage"
              id="messageInput"
              color="default-pink"
              :disable="disabledText"
              @paste.prevent="handlePaste"
              @keydown="handleKeydown"
              placeholder="Digite uma mensagem"
              v-model="text"
              @update:model-value="typingMessage()"
              type="textarea"
              autogrow
              dense
            >
              <template v-slot:prepend>
                <q-img
                  class="clip-chat"
                  @click="chooseFiles()"
                  :src="anexoImg"
                />
                <q-img
                  class="emoji-chat"
                  @click="() => (showEmojiPicker = !showEmojiPicker)"
                  :src="emoji"
                />
              </template>

              <template v-slot:append>
                <q-img class="send-message-chat" @click="send" :src="sendImg" />
              </template>
            </q-input>

            <input
              ref="refInputMidia"
              type="file"
              id="file"
              @change="uploadFile($event)"
              style="display: none"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
  <q-inner-loading
    :showing="loading"
    label="Por favor aguarde..."
    label-class="text-teal"
    label-style="font-size: 1.1em"
  >
    <div class="collumn justify-center q-my-md">
      <div style="height: auto; width: 50px; margin: auto">
        <LoadingIcon />
      </div>
      <label>Por favor aguarde...</label>
    </div>
  </q-inner-loading>

  <audio controls ref="refAudioChatInto" hidden>
    <source src="../../../assets/audio/chat.mp3" type="audio/wav" />
  </audio>
</template>

<script>
//----IMAGES
import sendImg from "../../../assets/icons/location_arrow.svg";
import anexoImg from "../../../assets/icons/paperclip.svg";
import emoji from "../../../assets/icons/emoji.svg";
import defaultImg from "../../../assets/images/avatar_default.svg";
//----COMPONENTS
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import ChatComponent from "@/components/chat/ChatComponent.vue";
import CreateGroupChat from "@/components/chat/dialog/CreateGroupChat.vue";
import ChatUsers from "@/components/chat/sidebar/ChatUsers.vue";
//----SERVICES
import ChatServices from "@/services/ChatServices";
import UserServices from "@/services/UserServices";
//----UTILS
import { useRouter } from "vue-router";
import { ENV } from "@/utils/env";
import CanUserAccessClass from "@/utils/CanUserAccessClass";
//----QUASAR
import { useQuasar } from "quasar";
//----VUEJS
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
// import picker compopnent
import EmojiPicker from "vue3-emoji-picker";
// import css
import "/node_modules/vue3-emoji-picker/dist/style.css";

import AlertsClass from "@/utils/Notifications/AlertsClass";

export default {
  name: "Chat",
  components: {
    DefaultBtn,
    Mural,
    CreateGroupChat,
    ChatComponent,
    LoadingIcon,
    ChatUsers,
    EmojiPicker,
  },
  setup() {
    //----CONSTANTS
    const $q = useQuasar();
    const _router = new useRouter();
    const store = useStore();
    const _chatServices = new ChatServices();
    const _userServices = new UserServices();
    const _optionListUsers = ref([]);
    const breadcrumbs = ref(["Chat"]);
    const Alerts = new AlertsClass();
    const userData = JSON.parse(localStorage.getItem("vuex")).userdata;
    const refInputMessage = ref(null);
    const refChatComponent = ref(null);
    const refInputMidia = ref(null);
    const refAudioChatInto = ref(null);
    const refCreateGroup = ref(null);
    const canUserAccessClass = new CanUserAccessClass();
    const urlAwsBucket = ENV.URL_AWS_BUCKET;
    const optionsStatus = ref([
      { label: "Online", icon: "info", value: true },
      { label: "Offline", icon: "info", value: false },
    ]);
    const onlineStatus = ref(store.state.userdata.online);

    //----VARIABLES
    let chats = ref([]);
    let messages = ref([]);
    let listUsers = ref([]);
    let userSelected = ref("");
    let groupForEdit = ref(null);
    let loading = ref(false);
    let loadingPostValue = ref(0);
    let selectedChatToUse = ref(false);
    let chatInAttendance = ref(null);
    let quantityMessage = ref(5);
    let pageMessage = ref(1);
    let disabledText = ref(false);
    let text = ref("");
    let dialogCreateGroup = ref(false);
    let indexChatActive = ref("");
    let position = ref(2080);
    let loadFile = ref(false);
    let showEmojiPicker = ref(false);
    function validateActionsPage(routeName) {
      if (!canUserAccessClass.canUserAccess(routeName, false)) {
        return false;
      }
      return true;
    }
    onMounted(() => {
      _listingNewChats();
      _listingTypingNewMessage();
      _listingNewMessages();
      _listingEmptyingMessage();
      _loadChats();
      _listAllUsers();
      _listingMessageDeleted();
      _listingMemberRemoved();
    });

    const isUserAdmin = computed(() => store.state.userdata.role_id === 1);

    function handleKeydown(event) {
      if (event.shiftKey && event.key === "Enter") {
        return; 
      }

      if (event.key === "Enter") {
        event.preventDefault();
        if (text.value.trim() !== "") { 
          _sendMessage(file.value); 
        }
      }
    }

    async function handlePaste(event) {
      if (event != null) {
        for (let i = 0; i < event.clipboardData.items.length; i++) {
          const item = event.clipboardData.items[i];
          if (
            item.kind == "file" &&
            (item.type.startsWith("image/") ||
              item.type.startsWith("video/mp4") ||
              item.type.startsWith("video/mp3"))
          ) {
            loadFile.value = true;
            text.value = "";
            await _sendMessage(item.getAsFile());
          }

          if (item.kind === "string" && item.type.startsWith("text/plain")) {
            text.value += event.clipboardData.getData("text");
          }
        }
      }
    }


    async function _loadChats() {
      await _chatServices.getAllChats().then((response) => {
        chats.value = response.data.filter((chat) => {
          return chat.deleted != true;
        });
      });
      _verifyNewMessageNotification();
    }
    function _verifyNewMessageNotification() {
      let chats = store.state.MessagesChat;
      chats.forEach((chat) => {
        _updateLastMessage(chat.conversationId, chat.message);
      });
    }
    async function _listAllUsers() {
      loading.value = true;
      await _userServices
        .getAllUserForCompany()
        .then((response) => {
          response.data.map((item) => {
            if (userData.id != item.id) {
              listUsers.value.push({
                label: item.first_name + " " + item.last_name,
                value: item.id,
                photo: item.avatar_location,
              });
            }
          });
          _optionListUsers.value = listUsers.value;
          loading.value = false;
        })
        .catch((e) => {
          loading.value = false;
        });
    }
    function _listingNewChats() {
      store.state.socket.connection.on("NotifyForNewChats", (chat) => {
        chats.value.push(chat);
      });
    }
    function _listingTypingNewMessage() {
      store.state.socket.connection.on(
        "TypingMessage",
        (conversationId, userId, name, avatar) => {
          if (
            chatInAttendance.value != null &&
            chatInAttendance.value.conversationId == conversationId
          ) {
            if (userData.id != userId) {
              let indexChat = messages.value.findIndex((chat) => chat.typing);
              if (indexChat < 0) {
                let message = {
                  typing: true,
                  name: name,
                  avatar_location: avatar,
                  user_id: userId,
                };
                messages.value.push(message);
                _scroll();
              }
            }
          }
        }
      );
    }
    function _listingEmptyingMessage() {
      store.state.socket.connection.on(
        "EmptyMessage",
        (conversationId, userId) => {
          if (chatInAttendance.value.conversationId == conversationId) {
            if (userData.id != userId) {
              let indexChat = messages.value.findIndex(
                (message) => message.typing == true
              );
              if (indexChat > 0) {
                messages.value.splice(indexChat);
              }
            }
          }
        }
      );
    }

    async function setMessageAsRead(conversationId, message) {
      const senderOtherThanMe = message.user_id !== store.state.userdata.id;

      if (senderOtherThanMe) {
        await _chatServices
          .SetMessageAsRead(conversationId)
          .then((response) => response.data);
      }
    }

    function _listingNewMessages() {
      store.state.socket.connection.on(
        "ReceiveMessage",
        (conversationId, message) => {
          const messageAlreadyInChat = messages.value.find(
            (MESSAGE) => MESSAGE.message_id === message.message_id
          );
          if (messageAlreadyInChat) return;

          if (chatInAttendance.value == null) {
            store.dispatch("AddMessages", {
              conversationId: conversationId,
              message: message,
            });
            _updateLastMessage(conversationId, message);
          } else {
            if (chatInAttendance.value.conversationId == conversationId) {
              setMessageAsRead(conversationId, message);
              let indexChat = messages.value.findIndex(
                (message) => message.typing == true
              );
              if (indexChat < 0) {
                messages.value.push(message);
              } else {
                messages.value[indexChat] = message;
              }
              _scroll();
            } else {
              store.dispatch("AddMessages", {
                conversationId: conversationId,
                message: message,
              });
              _updateLastMessage(conversationId, message);
              //_somChat();
            }
          }
        }
      );
    }
    function _updateLastMessage(conversationId, message) {
      let indexChat = chats.value.findIndex((chat) => {
        return chat.conversationId == conversationId;
      });

      const senderOtherThanMe = message.user_id !== store.state.userdata.id;

      if (indexChat >= 0 && senderOtherThanMe) {
        let lastMessage = message.name + ": " + message.message;
        chats.value[indexChat].lastMessage = lastMessage;
        chats.value[indexChat].timeLastMessage = message.HourSend;
        chats.value[indexChat].hasNewMessage = senderOtherThanMe;
        chats.value[indexChat].last_message = message.created_at;
        let chat = chats.value[indexChat];
        chats.value.splice(indexChat, 1);
        chats.value.unshift(chat);
        _changeTitleOfPage(lastMessage);
      }
    }
    function _listingMessageDeleted() {
      store.state.socket.connection.on(
        "MessageDeleted",
        (conversationId, messageId) => {
          if (chatInAttendance.value.conversationId == conversationId) {
            let index = messages.value.findIndex((message) => {
              return message.message_id == messageId;
            });
            messages.value[index].deleted_message = true;
          }
        }
      );
    }
    function _listingMemberRemoved() {
      store.state.socket.connection.on(
        "MemberRemoved",
        (conversationId, member) => {
          if (chatInAttendance.value.conversationId == conversationId) {
          }
        }
      );
    }
    function _scroll() {
      position.value = position.value + 150;
      setTimeout(() => {
        document.getElementById("scrollArea")?.scrollBy({
          top: position.value,
          left: 0,
          behavior: "smooth",
        });
      }, 100);
      // refChatComponent.value.scroll();
    }

    function toggleUser(userSelected) {
      // var userId = userSelected.value.value;
      // var userName = userSelected.value.label;
      // var userPhoto = userSelected.value.photo;
      var userId = userSelected.value;
      var userName = userSelected.label;
      var userPhoto = userSelected.photo;
      let indexUserInChat = chats.value.findIndex((chat) => {
        return chat.userofsend == userId;
      });
      if (indexUserInChat < 0) {
        var jsonSend = {
          conversationId: null,
          name: userName,
          photo_path: userPhoto,
          is_group: false,
          userofsend: userId,
          members: [
            {
              user_id: userId,
              is_admin: false,
            },
          ],
        };
        chats.value.unshift(jsonSend);
        setTimeout(() => {
          document.getElementById(`${0}`).click();
        }, 1000);
      } else {
        document.getElementById(indexUserInChat).click();
      }
    }

    async function clearCurrentChat(){
      chatInAttendance.value = null
    }

    async function selectedChat(chat, index) {
      indexChatActive.value = index;
      if (chatInAttendance.value != null) {
        if (chatInAttendance.value.conversationId == chat.conversationId) {
          return;
        }
        await clearCurrentChat()
      }
      chatInAttendance.value = chat;
      selectedChatToUse.value = true;
      pageMessage.value = 1;
      if (chat.conversationId != null) {
        let response = await _chatServices.getChat(
          chat.conversationId,
          pageMessage.value,
          quantityMessage.value
        );
        messages.value = response.data;
        _scroll();
        refChatComponent.value.emptyRef();
        _changeTitleOfPage("");
      } else {
        messages.value = [];
      }
      chat.hasNewMessage = false;
      chat.lastMessage = "";
      store.dispatch("RemoveMessages", chatInAttendance.value.conversationId);
    }
    function typingMessage() {
      if (chatInAttendance.value.conversationId != null) {
        if (text.value.length == 1) {
          store.state.socket.connection.invoke(
            "TypingMessage",
            chatInAttendance.value.conversationId.toString()
          );
        } else if (text.value.length == 0) {
          store.state.socket.connection.invoke(
            "EmptyMessage",
            chatInAttendance.value.conversationId.toString()
          );
        }
      }
    }
    function addEmojiInInput(emoji) {
      text.value = text.value + emoji.i;
      showEmojiPicker.value = false;
    }
    async function send() {
      if (text.value === "") return;
      await _sendMessage(null);
    }
    async function _sendMessage(file) {
      disabledText.value = true;

      const userId = chatInAttendance.value?.userofsend;

      const formattedMessage = formatMessage(text.value);

      const formData = new FormData();
      formData.append("Send_id", userId);
      formData.append("Message_user", formattedMessage); 
      formData.append("File", file);

      _emptyInputText();

      let indexChat = chats.value.findIndex(
        (chat) => chat?.userofsend === userId
      );

      if (chats.value[indexChat]?.conversationId == null) {
        const response = await _chatServices
          .createChatSendMessage(formData)
          .then((response) => {
            loadFile.value = false;
            return response;
          });
        chats.value[indexChat].conversationId = response.data.conversationId;
        messages.value.push(response.data.messages[0]);
      } else {
        _chatServices
          .sendMessage(
            chatInAttendance.value.conversationId,
            formData,
            loadingFile
          )
          .then(() => {
            loadFile.value = false;
          });
      }

      disabledText.value = false;
      loadFile.value = false;
    }
    function formatMessage(input) {
      return input.replace(/\n/g, "\n");
    }
    function loadingFile(value) {
      loadingPostValue.value = value;
    }
    function _emptyInputText() {
      text.value = "";
    }
    function chooseFiles() {
      refInputMidia.value.click();
    }
    async function uploadFile(event) {
      if (event != null) {
        loadFile.value = true;
        await _sendMessage(event.target.files[0]);
      }
    }
    async function saveGroup(group) {
      const formData = new FormData();
      if (group.conversationId != null) {
        formData.append("ConversationId", group.conversationId);
      }
      formData.append("Name", group.name);
      formData.append("Description", group.description);
      formData.append("Photo_path", group.photo_path);
      formData.append("Is_group", group.is_group);
      group.members.forEach((member) => {
        if (member.is_admin) {
          formData.append("Admins", member.user_id);
        }
        formData.append("Members", member.user_id);
      });
      if (!formData.has("Admins")) {
        formData.append("Admins", "");
      }
      if (!formData.has("Members")) {
        formData.append("Members", []);
      }
      refCreateGroup.value.loadingCreateGroup(true);
      if (group.conversationId == null) {
        await _chatServices
          .createChat(formData)
          .then(() => {
            dialogCreateGroup.value = false;
            refCreateGroup.value.loadingCreateGroup(false);
          })
          .catch((error) => {
            refCreateGroup.value.loadingCreateGroup(false);
            $q.notify({
              message: error.response.data.toString(),
              color: "red-9",
              position: "top",
            });
          });
      } else {
        await _chatServices
          .updateChat(formData)
          .then(() => {
            dialogCreateGroup.value = false;
            refCreateGroup.value.loadingCreateGroup(false);
          })
          .catch((error) => {
            refCreateGroup.value.loadingCreateGroup(false);
            if (
              error.response.data.toString() !=
              "Value cannot be null. (Parameter 'source')"
            ) {
              $q.notify({
                message: error.response.data.toString(),
                color: "red-9",
                position: "top",
              });
            }
          });
      }
      /*    _router.go(); */
    }
    function callRemoveGroup() {
      $q.notify({
        message: `Confirma a exclusão do grupo, todo o seu histórico e usuários?`,
        color: "red-9",
        position: "top",
        actions: [
          {
            label: "Sim",
            color: "yellow",
            handler: async () => {
              removeGroup();
            },
          },
          {
            label: "Não",
            color: "white",
            handler: async () => {},
          },
        ],
      });
    }
    async function removeGroup() {
      await _chatServices
        .deleteGroup(groupForEdit.value.conversationId)
        .then((response) => {
          _router.go();
        })
        .catch((error) => {});
    }
    // function _somChat(){
    //   document.getElementById("chatAudio").play();
    // }
    function _changeTitleOfPage(title) {
      document.title = "Lorni " + title;
    }

    function changeOnlineStatus() {
      _userServices
        .modifyOnlineStatus(onlineStatus.value)
        .then((response) => {
          store.commit("setOnlineStatus", response.data[0].online);
          Alerts.alertOf("O seu status foi modificado com sucesso", "green");
        })
        .catch((error) => {
          Alerts.alertOf(
            "Não foi possível modificar o seu status, por favor tente novamente!"
          );
        });
    }

    async function showGroup(chat) {
      loading.value = true;
      var response = await _chatServices.getChatGroup(chat.conversationId);
      groupForEdit.value = response.data;
      dialogCreateGroup.value = true;
      loading.value = false;
    }
    return {
      //----IMAGES
      sendImg,
      anexoImg,
      defaultImg,
      emoji,
      //----VARIABLES
      showEmojiPicker,
      groupForEdit,
      breadcrumbs,
      chats,
      loadFile,
      loadingPostValue,
      listUsers,
      userSelected,
      loading,
      selectedChatToUse,
      messages,
      userData,
      urlAwsBucket,
      refChatComponent,
      refInputMessage,
      refCreateGroup,
      text,
      disabledText,
      chatInAttendance,
      quantityMessage,
      refInputMidia,
      refAudioChatInto,
      dialogCreateGroup,
      indexChatActive,
      optionsStatus,
      onlineStatus,
      isUserAdmin,
      //----FUNCTIONS
      changeOnlineStatus,
      chooseFiles,
      showGroup,
      saveGroup,
      validateActionsPage,
      uploadFile,
      send,
      toggleUser,
      selectedChat,
      typingMessage,
      addEmojiInInput,
      callRemoveGroup,
      handlePaste,
      handleKeydown,
    };
  },
};
</script>

<style lang="scss">
.chat-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  overflow: auto;
  main {
    overflow: auto;
    hr {
      width: 100%;
      height: 1px;
      min-height: 1px;
      background: #e5e5e5;
      border: none;
      margin-bottom: 0.5rem;
    }
  }
  @media (min-width: 800px) {
    flex-direction: column;
  }
}
.message-container {
  max-height: 80vh;
  height: 72vh;
}
.chat-content {
  height: 100% !important;
  overflow: hidden;
}
.chat-conversantions {
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.07);
  padding: 10px 0px;
  border-radius: 20px;
}
.chat-container {
  max-height: 68vh;
  padding-right: 10px;
  overflow-y: scroll;
  .image-user-chat {
    height: 100% !important;
    width: auto !important;
  }
}
.chat-attendance {
  border-radius: 22px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.07);
}
.active-chat-attendance {
  color: white;
  background: #000000;
}
.input-chat {
  position: relative;
}
.emoji-picker {
  position: absolute;
  bottom: 110%;
}
.clip-chat {
  cursor: pointer;
  width: 20px;
  height: 25px;
}
.emoji-chat {
  cursor: pointer;
  width: 22px;
  height: 25px;
}
.send-message-chat {
  cursor: pointer;
  width: 25px;
  height: auto;
}
.search-user {
  color: #fe5268;
  font-weight: bold;
}

.container-options {
  display: flex;
  gap: 16px;
}

.container-status {
  width: 122px;
}

.container-label {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.container-label .label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: #666666;
}

.status {
  width: 15px;
  height: 15px;
  border: 2px solid white;
  border-radius: 15px;
  margin-right: 9.6px;
}

.online {
  background: #09b709;
}

.offline {
  background: red;
}
</style>
