<template>
  <div class="col-12 q-px-md q-pb-sm">
    <q-bar
      class="col-12 bg-white text-black"
      style="border-bottom: 1px solid #ccc"
    >
      <div class="cursor-pointer" v-if="chat?.is_group">
        <span class="text-bold">{{ chat?.is_group ? "Grupo - " : "" }}</span> "{{
          chat.name
        }}"
      </div>
      <div v-else>{{ chat.name }}</div>
      <q-space />
      <q-btn
        v-if="canIEdit"
        dense
        flat
        icon="edit"
        @click="$emit('showGroup', chat)"
      />
    </q-bar>

    <q-infinite-scroll
      @load="onLoad"
      id="scrollArea"
      ref="scrollAreaRef"
      :offset="5"
      reverse
      class="scroll"
    >
      <template v-slot:loading>
        <div
          class="row justify-center q-my-md"
          style="height: auto; width: 40px; margin: auto"
        >
          <LoadingIcon />
        </div>
      </template>
      <div class="full-width" v-for="(message, index) in messages" :key="index">
        <div v-if="message.user_id == userData.id">
          <ChatMessageBalloon :messageUser="message" :isSent="true">
            <template v-slot:message>
              <MessageComponent
                :message="message"
                :messageForUser="true"
                :urlAwsBucket="urlAwsBucket"
                @disabled="(message) => disabled(message)"
              />
            </template>
          </ChatMessageBalloon>
        </div>
        <div v-else>
          <q-chat-message
            v-if="message.typing"
            :name="message.name"
            :avatar="
              message.avatar_location == '' ||
              message.avatar_location == undefined
                ? defaultImg
                : urlAwsBucket + message.avatar_location
            "
            size="6"
          >
            <q-spinner-dots size="2rem" />
          </q-chat-message>
          <ChatMessageBalloon v-else :messageUser="message" :isSent="false">
            <template v-slot:message>
              <MessageComponent
                :message="message"
                :urlAwsBucket="urlAwsBucket"
              />
            </template>
          </ChatMessageBalloon>
        </div>
      </div>
      <slot name="loadFile"></slot>
    </q-infinite-scroll>
  </div>

  <q-inner-loading
    :showing="loading"
    label="Por favor aguarde..."
    label-class="text-teal"
    label-style="font-size: 1.1em"
  >
    <div class="collumn justify-center q-my-md">
      <div style="height: auto; width: 50px; margin: auto">
        <LoadingIcon />
      </div>
      <label>Por favor aguarde...</label>
    </div>
  </q-inner-loading>
</template>

<style></style>

<script>
//----IMAGES
import defaultImg from "../../assets/images/avatar_default.svg";

//----COMPONENTS
import LoadingIcon from "@/components/LoadingIcon";
import MessageComponent from "@/components/chat/MessageComponent";
import ChatMessageBalloon from "@/components/chat/ChatMessageBalloon.vue";

//----SERVICES
import ChatServices from "@/services/ChatServices";

//----QUASAR
import { useQuasar } from "quasar";

//----VUEJS
import { computed, ref } from "vue";

import { useStore } from "vuex";

export default {
  name: "ChatComponent",
  components: {
    LoadingIcon,
    MessageComponent,
    ChatMessageBalloon,
  },
  props: {
    messages: Array,
    chat: Object,
    quantityMessage: Number,
    userData: Object,
    urlAwsBucket: String,
  },
  emits: ["scroll", "showGroup", "disabledMessage"],
  setup(props) {
    //----CONSTANTS
    const _chatServices = new ChatServices();
    const position = ref(2080);
    const $q = new useQuasar();
    const scrollAreaRef = ref(null);
    const store = new useStore();

    //----VARIABLES
    let pageMessage = ref(1);
    let containsMessages = ref(true);
    let loading = ref(false);

    async function onLoad(index, done) {
      if (props.messages.length > 0) {
        if (containsMessages.value) {
          pageMessage.value = pageMessage.value + 1;
          let response = await _chatServices.getChat(
            props.chat.conversationId,
            pageMessage.value,
            props.quantityMessage
          );
          containsMessages.value = response.data.length > 0;
          response.data.reverse().forEach((message) => {
            props.messages.unshift(message);
          });
        } else {
          document.getElementById("scrollArea").scrollBy({
            top: 50,
            left: 0,
            behavior: "smooth",
          });
        }
      }
      done();
    }

    async function disabled(message) {
      $q.notify({
        message: `Deseja realmente remover esta mensagem?`,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: async () => {
              await _removeMessage(message.message_id);
            },
          },
        ],
      });
    }

    async function _removeMessage(id) {
      loading.value = true;
      await _chatServices
        .removeMessage(props.chat.conversationId, id)
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          alertErro(error.response.data.toString());
          loading.value = false;
        });
    }

    function alertErro(message) {
      $q.notify({
        message: message,
        color: "red-9",
        position: "top",
      });
    }

    function emptyRef() {
      position.value = 2080;
      pageMessage.value = 1;
      containsMessages.value = true;
    }

    function scroll() {
      position.value = position.value + 150;
      setTimeout(() => {
        document.getElementById("scrollArea").scrollBy({
          top: position.value,
          left: 0,
          behavior: "smooth",
        });
      }, 100);
    }
    

    const canIEdit = computed(() => {
      if (props.chat.is_group){
        return props.chat.is_admin || store.state.userdata.role_id === 1
      }
      return false
    });

    return {
      //----IMAGES
      defaultImg,

      //----VARIABLES
      scrollAreaRef,
      loading,
      canIEdit,

      //----FUNCTIONS
      disabled,
      scroll,
      emptyRef,
      onLoad,
    };
  },
};
</script>

<style lang="scss" scoped>
.scroll {
  height: 64vh;
  max-height: 95vh;
  overflow: auto;
}
.chat-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  overflow: auto;
  padding: 0 2%;

  main {
    overflow: auto;

    hr {
      width: 100%;
      height: 1px;
      min-height: 1px;
      background: #e5e5e5;
      border: none;
      margin-bottom: 0.5rem;
    }
  }
  .avatar-chat {
    height: 48px;
    width: 48px;
    border-radius: 48px;
    overflow: hidden;
    margin-right: 12px;

    .user-image {
      height: 100%;
    }
  }
  .avatar-sent {
    margin-left: 12px;
  }
  .avatar-recive {
    margin-right: 12px;
  }

  @media (min-width: 800px) {
    flex-direction: column;
  }
}
.message-container {
  max-height: 80vh;
  height: 75vh;
}

.attachment {
  height: 170px;
  max-width: 300px;
}
</style>
